import React from 'react';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { useUser } from 'store/user';

import {
  AppBar as MatAppBar
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { AvatarMenu, Button, Icon } from 'components';
import { UiHeader, UiToolbar, UiToolItemContainer, UiToolItem, UiLogo } from './Header.style';
import getStatic from 'utils/getStatic';
import roundRocket from '@iconify/icons-ic/round-rocket';


const homeLogo = getStatic('logoW');

/**
 * Header
 */
const useStyles = makeStyles({
	paper: {
		boxShadow: '1px 2px 5px 1px rgba(0, 0, 0, 0.3)',
	},
});
export const Header = () => {
  const [{ profile }] = useUser();
  const classes = useStyles();
  const history = useHistory();
  // const newsMatch = useRouteMatch('/home/news');
  const sessionMatch = useRouteMatch('/sessions');
  const classMatch = useRouteMatch('/home'); // TODO 之後改 /home/class
  const goPath = path => () => history.push(path);

  const ToolItems = [
    // {
    //   label: '動態消息',
    //   path: '/organization',
    //   match: Boolean(newsMatch)
    // },
    {
      label: '我的課堂',
      path: '/sessions',
      match: Boolean(sessionMatch),
      icon: 'importContacts'
    },
    {
      label: '我的班級',
      path: '/home',
      match: Boolean(classMatch),
      icon: 'emojiSymbols'
    }
  ];

  return (
    <UiHeader>
      <MatAppBar className={classes.paper} position="static">
        <UiToolbar>
          <UiLogo onClick={() => { history.push('/home'); }}>
            <img src={homeLogo} alt='oneClass' />
          </UiLogo>
          {
            (profile.id && profile.role !== 'admin') &&
            <>
              <UiToolItemContainer>
                {
                  ToolItems && ToolItems.map((tab, idx) =>
                    <UiToolItem
                      active={tab.match}
                      key={idx}
                      onClick={goPath(tab.path)}
                    >
                      <Icon name={tab.icon} haveBg={false} icolor='#325b88' />
                      {tab.label}
                    </UiToolItem>
                  )
                }
              </UiToolItemContainer>
              {
                profile.isTeacher &&
                <Button
                  iconify={roundRocket}
                  buttonColor='bar'
                  textColor='#325b88'
                  onClick={() => {
                    history.push('/upgradeAccount');
                  }}
                >
                  升級專業版
                </Button>
              }

            </>
          }

          <AvatarMenu />
        </UiToolbar>
      </MatAppBar>
    </UiHeader >
  );
};
